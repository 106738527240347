<template>
    <div class="p-0">
        <div class="list-propositions container p-0">
            <div v-if="! isMobile" class="tabs-block">
                <ul class="status-tabs">
                    <li @click="activateTab(status)" v-for="(status, index) in linkStatuses" class="tab">
                        <a v-bind:class="{ active: listQuery.statusId === status.id }"
                           href="javascript:void(0);"> {{ status.name || 'Всі' }}</a>
                    </li>
                </ul>
            </div>
            <div v-else>
                <div class="form-group">
                    <el-select v-model="listQuery.statusId"
                               class="form-control-el"
                               placeholder="Виберіть статус">
                        <el-option v-for="item in linkStatuses"
                                   :key="item.id"
                                   :label="item.name || 'Всі'"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div class="container empty-notification" v-if="! isLoadData && (linksList && linksList.length < 1)">
                <img src="~@/assets/images/empty_img.svg" alt="">
                <div class="empty-notification__text">
                    <p v-if="isCompletedTab()">Тут будуть з`являтися посилання, які вже виконані.</p>
                    <p v-else-if="isSentTab()">Тут будуть з'являтися посилання зі статусом відправлено. Перейдіть за посиланням та виберіть пропозицію або або змініть параметри <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a> посиланнь.</p>
                    <p v-else-if="isReceivedTab()">Тут будуть з'являтися посилання зі статусом Пропозицію обрано. Дочекайтесь покаи ваш агент формить вам поліс або змініть параметри <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a> посиланнь.</p>
                    <p v-else-if="isOverdueTab()">Тут будуть з'являтися страхові поліси зі статусом Просрочено. Змінити параметри <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a> посиланнь.</p>
                    <p v-else>Ваш список посилань поки порожній.
                        <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a> посиланнь.</p>
                </div>
                <a data-toggle="modal" data-target="#modalSearch" class="header__create">Cтворити
                    страховку</a>
            </div>


            <div class="container-wrapp" v-else-if="! isMobile">
                <div class="header-block">
                    <ul class="columns-block">
                        <li class="column">ID</li>
                        <li class="column">Тип Продукту</li>
                        <li class="column">Дата</li>
                        <li class="column">Страхувальник</li>
                        <li class="column">Нотатки</li>
                        <li class="column">Обрані пропозиції</li>
                    </ul>
                </div>
                <div v-if="isLoadData">
                    <row-block-skeleton v-for="index in 4" :key="index"></row-block-skeleton>
                </div>
                <div v-else v-for="link in linksList" class="row-block">
                    <ul class="columns-block">
                        <li class="column">
                            <div class="column-block">
                                <span class="order-type">{{ link.numberId }}</span>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <span class="order-type">{{ link.productName }}</span>
                                <div class="bottom-part order-status">
                                    <div class="bottom-block right-border">
                                        <span>Статус:</span>
                                        <div :class="['status', getStatusClass(link)]">
                                            <span>{{ showStatusName(link) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <div>
                                    <div class="date-block">
                                        <div class="text">створена:</div>
                                        <span v-if="link.createdAt" class="date">{{ link.createdAt }}</span>
                                        <span v-else class="date">&mdash;</span>

                                    </div>
                                    <div class="date-block">
                                        <div class="text">дійсна до:</div>
                                        <span v-if="link.expiresIn" class="date">{{ link.expiresIn }}</span>
                                        <span v-else class="date">&mdash;</span>
                                    </div>
                                </div>
                                <div class="bottom-part order-user">
                                    <div class="bottom-block right-border">
                                        <span>Точка продажу:</span>
                                        <div class="user-name"
                                             data-toggle="tooltip" data-placement="bottom"
                                             :title="link.insured.fullName || ''">
                                             {{ link.insured.fullName || '' }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <div class="order-insured">
                                    <a class="dark-link" v-if="link.clientId" :href="'/clients/'+link.clientId">
                                        <div class="">{{ link.fullName }}</div>
                                    </a>
                                    <div v-else>
                                        <div v-if="showNameInsurantSurname(link)">
                                            <span>{{ showNameInsurantSurname(link) }}</span>
                                        </div>
                                        <div style="">
                                            <span>{{ showNameInsurantName(link) }}</span>
                                        </div>
                                        <div style="">
                                            <span>{{ showNameInsurantPatronymic(link) }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="bottom-part order-user">
                                    <div class="bottom-block right-border">
                                        <span>Укладений поліс:</span>
                                        <div v-if="link.orderData && showMtsbuCode(link.orderData)" class="user-name">
                                            {{ showMtsbuCode(link.orderData) }}
                                        </div>
                                        <div v-else class="user-name"> - </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <div class="order-insured">
                                    <div v-if="link.comment" class="proposition-comment">{{ link.comment }}</div>
                                    <span v-else class="order-type"> - </span>
                                </div>

                                <div v-if="link.responseData && isReceived(link) && isClearDraft(link)" class="bottom-part order-user justify-content-end">
                                    <div class="bottom-block right-margin">
                                        <svg style="margin-bottom: 5px" width="16" height="16"
                                             viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <mask id="path-1-inside-1" fill="white">
                                                <rect x="1" y="5" width="14" height="10" rx="1"/>
                                            </mask>
                                            <rect x="1" y="5" width="14" height="10" rx="1"
                                                  stroke="#999DA6" stroke-width="4"
                                                  mask="url(#path-1-inside-1)"/>
                                            <mask id="path-2-inside-2" fill="white">
                                                <rect x="4" y="1" width="8" height="6" rx="1"/>
                                            </mask>
                                            <rect x="4" y="1" width="8" height="6" rx="1"
                                                  stroke="#999DA6" stroke-width="4"
                                                  mask="url(#path-2-inside-2)"/>
                                        </svg>
                                        <a @click="createOrProceed(link)" href="javascript:void(0);">
                                            <span style="margin-left: 3px;">Укласти</span>
                                        </a>
                                    </div>
                                </div>
                                <div v-if="isCompleted(link)" class="bottom-part order-user justify-content-end">
                                    <div class="bottom-block right-margin">
                                         <span class="icon">
                                            <svg style="margin-bottom: 7px;" width="16" height="16"
                                                 viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
                                                    stroke="#999DA6" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"/>
                                                <path
                                                    d="M4.6665 6.66666L7.99984 9.99999L11.3332 6.66666"
                                                    stroke="#999DA6" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8 10V2" stroke="#999DA6" stroke-width="2"
                                                      stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                        <a @click="takeContract(link, 1)" href="javascript:void(0);">
                                            <span style="margin-left: 3px;">Завантажити поліс</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <a v-if="link.responseData && (isReceived(link) || isCompleted(link))" @click="" class="choice-link" href="javascript:void(0);">
<!--                                    <span class="option" style="border-bottom: 1px #009BEB dashed;">-->
<!--                                        Обрані пропозиції: {{ getChoiceCount(order) }}-->
<!--                                    </span>-->
                                    <div class="d-inline-flex">
                                        <client-choice-info
                                            :offer-data="link"
                                            :choice-count="getChoiceCount(link)"
                                            ref="clientInfo"
                                        ></client-choice-info>
                                    </div>
                                </a>
                                <span v-else class="order-type"> - </span>

                                <div class="bottom-part">
                                    <div class="bottom-block right-border">
                                        <span class="option image">
                                              <svg style="margin-bottom: 3px" width="16" height="16"
                                                   viewBox="0 0 16 16" fill="none"
                                                   xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0)">
                                                <path
                                                    d="M0.666504 8C0.666504 8 3.33317 2.66667 7.99984 2.66667C12.6665 2.66667 15.3332 8 15.3332 8C15.3332 8 12.6665 13.3333 7.99984 13.3333C3.33317 13.3333 0.666504 8 0.666504 8Z"
                                                    stroke="#24282C" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                                <path
                                                    d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                                                    stroke="#24282C" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0">
                                                    <rect width="16" height="16" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                        </span>
<!--                                        <a @click="" href="javascript:void(0);">-->
<!--                                            <span class="option">Переглянути</span>-->
<!--                                        </a>-->
                                        <router-link :to="{ name: 'links', params: { id: link.id }}" tag="a" target="_blank">
                                            <span class="">Переглянути</span>
                                        </router-link>

                                        <span v-if="loadMoreOptions[link.id + link.typeId] === true"
                                              class="option-loader spinner-border"
                                              style="height: 14px; width: 14px; margin-bottom: 4px;"
                                              role="status">
                                        </span>
                                        <div v-else style="margin-left: 15px;">
                                            <more-options
                                                          :status-order="showStatusId(link)"
                                                          :link-type="link.typeId"
                                                          :link-status="link.statusId"
                                                          :link="link"
                                                          @copy-link="copyLink(link)"
                                                          @show-link="goToLinkInfo(link)"
                                                          @create-order="createOrProceed(link)"
                                                          @proceed-order="proceedOrder(link)"
                                                          @download-contract="takeContract(link, 1)"
                                                          :is-exist-mtsbu-link="canShowMtsbuLink(link)"
                                                          @open-mtsbu-link="openMtsbuLink(link.orderData)"
                                            >
                                            </more-options>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>


            <div v-else>
                <div class="mobile-columns-block">
                    <div v-if="isLoadData">
                        <row-mobile-block-skeleton v-for="index in 4" :key="index"></row-mobile-block-skeleton>
                    </div>
                    <div v-else>
                        <div v-for="link in linksList" class="row order-data">

                            <div class="col-7 half-block">
                                <div class="info-block">
                                    <div class="option">ID</div>
                                    <div class="value">{{ link.numberId }}</div>
                                </div>
                                <div class="info-block">
                                    <div class="option">Дата</div>
                                    <div class="sub-options">
                                        <div>
                                            <span class="sub-option">створена:</span>
                                            <span v-if="link.createdAt" class="value">{{ link.createdAt }}</span>
                                            <span v-else class="value">&mdash;</span>
                                        </div>
                                        <div>
                                            <span class="sub-option">дійсна до:</span>
                                            <span v-if="link.expiresIn"
                                                  class="value">{{ link.expiresIn }}</span>
                                            <span v-else class="value">&mdash;</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="option">Обрані пропозиції</div>
                                    <div class="value overflow-visible" style="overflow: visible!important">
                                        <a v-if="link.responseData && (isReceived(link) || isCompleted(link))" @click="" class="choice-link" href="javascript:void(0);">
                                            <div class="d-inline-flex">
                                                <client-choice-info
                                                    :offer-data="link"
                                                    :choice-count="getChoiceCount(link)"
                                                    ref="clientInfo"
                                                ></client-choice-info>
                                            </div>
                                        </a>
                                        <span v-else class="order-type"> - </span>
                                    </div>
                                    <div class="sub-options">
                                        <div>
                                            <span class="sub-option">Укладений поліс:</span>
                                            <div v-if="link.orderData && showMtsbuCode(link.orderData)" class="value">
                                                {{ showMtsbuCode(link.orderData) }}
                                            </div>
                                            <div v-else class="value"> - </div>
                                        </div>

                                        <div class="mt-4 mb-1">
                                            <span class="sub-option">Статус:</span>
                                            <div :class="['value status', getStatusClass(link)]">
                                                <span>{{ showStatusName(link) }}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <span class="sub-option">Точка продажу:</span>
                                            <span data-toggle="tooltip" data-placement="bottom"
                                                  :title="link.insured.fullName || ''"
                                                  class="value">
                                            {{ link.insured.fullName || '' }}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5 half-block">
                                <div class="info-block">
                                    <div class="option">Тип Продукту</div>
                                    <div class="value">
                                        <span v-if="link.productName" class="order-number">{{ link.productName }}</span>
                                        <span class="order-number" v-else>&mdash;</span>
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="option">Страхувальник</div>
                                    <a class="dark-link" v-if="link.clientId" :href="'/clients/'+link.clientId">
                                        <div class="value">{{ link.fullName }}</div>
                                    </a>
                                    <div v-else class="value">{{ link.fullName}}</div>
<!--                                    <div class="value">-->
<!--                                        {{ showNameInsurantSurname(order) }}-->
<!--                                    </div>-->
<!--                                    <div class="value">-->
<!--                                        {{ showNameInsurantName(order) }}-->
<!--                                    </div>-->
<!--                                    <div class="value">-->
<!--                                        {{ showNameInsurantPatronymic(order) }}-->
<!--                                    </div>-->
                                </div>
                                <div class="info-block">
                                    <div class="option">Нотатки</div>
                                    <div class="value text-wrap proposition-comment">
                                        <span v-if="link.comment">{{ link.comment }}</span>
                                        <span class="order-number" v-else>&mdash;</span>
                                    </div>
                                </div>

                                <div style="margin-top: auto;color: #999DA6;" class="info-block">
                                    <div class="info-link">
                                        <svg width="16" height="16"
                                             viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                                <path
                                                    d="M0.666504 8C0.666504 8 3.33317 2.66667 7.99984 2.66667C12.6665 2.66667 15.3332 8 15.3332 8C15.3332 8 12.6665 13.3333 7.99984 13.3333C3.33317 13.3333 0.666504 8 0.666504 8Z"
                                                    stroke="#999DA6" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                                <path
                                                    d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                                                    stroke="#999DA6" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="16" height="16" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <router-link :to="{ name: 'links', params: { id: link.id }}" tag="a" target="_blank">
                                            <span class="option">Переглянути</span>
                                        </router-link>
<!--                                        <span @click="goToOrderInfo(order)">Переглянути</span>-->
                                    </div>
                                    <div>
                                        <div v-if="loadMoreOptions[link.id + link.typeId] === true"
                                             class="option-loader spinner-border"
                                             style="height: 20px; width: 20px; margin-top: 11px;"
                                             role="status">
                                        </div>
                                        <more-options v-else
                                                      :status-order="showStatusId(link)"
                                                      :link-type="link.typeId"
                                                      :link-status="link.statusId"
                                                      :link="link"
                                                      @copy-link="copyLink(link)"
                                                      @show-link="goToLinkInfo(link)"
                                                      @create-order="createOrProceed(link)"
                                                      @proceed-order="proceedOrder(link)"
                                                      @download-contract="takeContract(link, 1)"
                                                      :is-exist-mtsbu-link="canShowMtsbuLink(link)"
                                                      @open-mtsbu-link="openMtsbuLink(link.orderData)"
                                        ></more-options>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Vue from "vue";
import {customerMixin, HistoryMixin, linkMixin} from '@/mixin'
import {calculate} from "@/api/offer";
import {saveOrder} from "@/api/order";
import {MoreOptions, RowBlockSkeleton, RowMobileBlockSkeleton, ClientChoiceInfo} from './components'
import {GetPaymentLink as ModalGetInvoiceOrder, GetInstantLoan as ModalGetInstantLoan, GetMonoPayParts as ModalGetMonoPayParts, GetAllPaymentWays as ModalGetAllPaymentWays} from "@/components/app/modals/order";
import {contractGreenCard} from "@/api/contractGreenCard";
import {downloadTourismContract} from "@/api/downloadTourismContract";
import {orderTourism} from "@/api/orderTourism";
import {getByParams as getInsuredByParams} from "@/api/insured";

const FileDownload = require('js-file-download');

export default {
    mixins: [customerMixin, HistoryMixin, linkMixin],
    components: {MoreOptions, RowBlockSkeleton, RowMobileBlockSkeleton, ClientChoiceInfo, ModalGetInvoiceOrder, ModalGetInstantLoan, ModalGetMonoPayParts, ModalGetAllPaymentWays},
    props: {
        countProlongation: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        isLoadData: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        isMobile: {
            type: Boolean,
            default: () => {
                return true;
            }
        },
        prolongation: {
            type: Array,
            default: () => {
                return [{
                    dgo: {},
                    autolawyer: {},
                    statusId: null,
                    fullName: null,
                    earning: null,
                    contract: {},
                    companyInfo: {},
                    insurant: {},
                    insured: {},
                    price: null,
                    dgoPrice: null,
                    discountPrice: null,
                    cashbackPrice: null,
                }];
            },
        },
        links: {
            type: Array,
            default: () => {
                return [{
                    dgo: {},
                    autolawyer: {},
                    statusId: null,
                    clientId: null,
                    fullName: null,
                    requestData: null,
                    responseData: null,
                    expiresIn: null,
                    createdAt: null,
                    earning: null,
                    contract: {},
                    contractDgo: {},
                    companyInfo: {},
                    insurant: {},
                    insured: {},
                    price: null,
                    dgoPrice: null,
                    discountPrice: null,
                    cashbackPrice: null,
                }];
            },
        },
        linkStatuses:{
            type: Array,
            default: () => []},
    },

    data: function () {
        return {
            loadContract: {},
            loadConclude: {},
            loadMoreOptions: {},
            isCopyProcess: false,
            linksList: [],
            ordersResponceList: [],
            listQuery: {
                statusId: 0,
            },
        }
    },
    created() {
        this.linksList = this.links;
    },
    computed: {
        orderTypes: function () {
            return this.$store.getters.ORDER_TYPES
        },
        getParentUserId() {
            return this.$store.getters.USER_INFO.parentId ?? this.$store.getters.USER.id;
        }
    },
    methods: {
        showClientInfo() {
            this.$refs.clientChoiceInfo.handle(true);
        },
        filterStatusWrap: function() {
            return this.$emit('filter-status-wrap');
        },
        getChoiceCount(offer) {
            let countProps = 0;
            // main offer data
            let responseData = offer.responseData.orderDraft;
            if (responseData &&
                responseData?.offerId &&
                responseData?.companyId &&
                responseData?.price
            ) {
                countProps++;
            }

            if (responseData && responseData?.dgo?.id) {
                countProps++;
            }
            if (responseData && responseData?.autolawyer?.id) {
                countProps++;
            }
            return countProps;
        },
        copyLink(link) {
            let props = this.$router.resolve({
                name: 'links',
                params: { id: link.id },
            });
            const absoluteURL = new URL(props.href, window.location.origin + window.location.pathname).href;
            navigator.clipboard.writeText(absoluteURL);
        },
        isSentTab: function () {
            return this.listQuery.statusId === this.sentStatus;
        },
        isReceivedTab: function () {
            return this.listQuery.statusId === this.receivedStatus;
        },
        isCompletedTab: function () {
            return this.listQuery.statusId === this.completedStatus;
        },
        isOverdueTab: function () {
            return this.listQuery.statusId === this.overdueStatus;
        },
        isSent: function(link) {
            return link.statusId === this.sentStatus;
        },
        isReceived: function(link) {
            return link.statusId === this.receivedStatus;
        },
        isCompleted: function(link) {
            return link.statusId === this.completedStatus;
        },
        isOverdue: function(link) {
            return link.statusId === this.overdueStatus;
        },
        isClearDraft(linkData) {
            if (!linkData.orderData || linkData.orderData?.statusId == -1) {
                return true;
            } else {
                return false;
            }
        },
        canShowMtsbuLink(linkData) {
            if (linkData.orderData) {
                return this.showMtsbuLink(linkData.orderData);
            }
            return false;
        },
        showMonths: function(order) {
            if(order.typeId !== this.orderTypes['dgo'] && order.usageMonths != null && order.usageMonths != undefined && order.usageMonths != '')
                return true;
            return false;
        },
        activateTab :function (status) {
            this.$set(this.listQuery, 'statusId', status.id);
        },
        takeContract: async function (linkData, type) {
            let linksList = [];
            const order = linkData.orderData;

            if (order.typeId == 1 && order.dgo)
            {
                this.ordersResponceList = await this.downloadData(order, linksList, type)
            }
            else{
                this.downloadData(order, linksList, 1)
            }
        },
        createOrProceed(linkData) {
            if (linkData.orderData) {
                this.proceedOrder(linkData)
            } else {
                this.createOrder(linkData);
            }
        },
        proceedOrder(linkData) {
            this.declare(linkData.orderData);
        },
        createOrder(linkData) {
            let orderDraft  = linkData.responseData?.orderDraft;
            let requestData = linkData.requestData?.searchParams ?? null;
            let data = linkData.responseData?.data;
            //let offer = linkData.responseData?.offer;
            let offer = Object.assign({}, linkData.responseData?.offer);
            // если есть clientId то забиваем объект с данными клиента
            let userData = this.getClientInfo(linkData);
            let userCar = this.getClientCar(linkData);
            if (linkData.clientId && userData) {
                offer.userData = userData;
                data.customer = userData;
                data.car = userCar;
            }

            orderDraft.link_id = linkData.id; //передаем линк id для связки линки и ордера
            data.link_id = linkData.id; //передаем линк id для связки линки и ордера
            offer.link_id = linkData.id; //передаем линк id для связки линки и ордера

            this.$store.commit('SET_INFO_ORDER', {offer: offer, requestData: requestData});

            if (orderDraft) {
                saveOrder(orderDraft).then(response => {

                    if (response?.data?.data[0]?.statusId === -1) {
                        if (requestData && !requestData?.fromProlongation) {
                            this.$store.commit('SET_FULL_INFO_ORDER', null);
                        }else{
                            let fullInfoOrder = JSON.parse(JSON.stringify(this.$store.state.osagoStore.fullInfoOrder));
                            data.customer = fullInfoOrder.customer;
                            data.car = fullInfoOrder.car;
                        }
                       // this.orderId = response?.data?.data[0]?.id;
                        data.orderId = response?.data?.data[0]?.id;
                        delete data.statusId;
                        delete data.orderDraft;

                        this.$store.commit('SET_FULL_INFO_ORDER', data);
                        //console.log('fullInfoOrder', JSON.parse(JSON.stringify(this.$store.state.osagoStore.fullInfoOrder)));
                        this.$router.push('/order-step').catch(() => {
                            this.$router.push('/order-step');
                        });
                    }
                });
            }

        },
        getClientInfo(linkData) {

            let userData = null;

            if (linkData.clientInfo) {
                userData = {
                    name: linkData.clientInfo.name,
                    phone: linkData.clientInfo.phone,
                    email: linkData.clientInfo.email,
                    surname: linkData.clientInfo.surname,
                    dateBirth: linkData.clientInfo.dateBirthAt,
                    patronymic: linkData.clientInfo.patronymic,
                    identificationCode: linkData.clientInfo.documentation.uin,
                    withoutIdentificationCode: linkData.clientInfo.documentation.withoutUin,
                    withoutDocuments: linkData.clientInfo.documentation.withoutDocuments,
                    documentType: linkData.clientInfo.documentation.type,
                    documentation: {
                        type: linkData.clientInfo.documentation.documentType,
                        serial: linkData.clientInfo.documentation.serial,
                        number: linkData.clientInfo.documentation.number,
                        issuedBy: linkData.clientInfo.documentation.issuedBy,
                        dateOfIssue: linkData.clientInfo.documentation.dateOfIssue,
                        endDateOfIssue: linkData.clientInfo.documentation.endDateOfIssue,
                    },
                    address: {
                        street: linkData.clientInfo.street,
                        house: linkData.clientInfo.house,
                        number: linkData.clientInfo.apartment,
                        cityId: linkData.clientInfo.cityId,
                        cityObject: linkData.clientInfo.cityObject,
                        cityName: linkData.clientInfo.city,
                    },
                };
            }

            return userData;
        },
        getClientCar(linkData) {
            let userCar = null;
            if (linkData.carInfo) {
                userCar = {
                    vin: linkData.carInfo.vin,
                    year: linkData.carInfo.year,
                    brand: linkData.carInfo.brand,
                    model: linkData.carInfo.model,
                    number: linkData.carInfo.number
                };
            }
            return userCar;
        },
    },
    watch: {
        'listQuery.statusId': function (newValue, oldValue) {
            return this.$emit('init-links-list');
        },
        'links': {
            handler: function (val, oldVal) {
                this.linksList = val;
            },
            deep: true
        },
        'ordersResponceList': {
            handler: function (val, oldVal) {
                this.linksList = this.updateExtraFiles(this.linksList, val);
            },
            deep: true
        },
    }
}
</script>
