<template>
    <div v-click-outside="hide">
        <div v-if="condition.isShow" style="position: relative">
            <div class="info-block">
               <div class="products-info">
                   <div class="info-title">
                       {{ offerData.productName || 'ОСЦПВ' }}
                   </div>
                   <div class="info-item">
                       <div class="option">СК:</div>
                       <div v-if="offerData.companyInfo" class="info">
                           {{ offerData.companyInfo.public_name || ''}}
                       </div>
                   </div>
                   <div class="info-item">
                       <div class="option">Франшиза:</div>
                       <div class="info">
                           {{ Number(offerData.offerInfo.franchise) }} грн
                       </div>
                   </div>
                   <div class="info-item">
                       <div class="option">Вартість:</div>
                       <div class="info">
                           {{ offerData.offerInfo.price }} грн
                       </div>
                   </div>

                   <div v-if="orderDraft.dgo.id">
                       <hr>
                       <div class="info-title">
                           Додаткове покриття
                       </div>
                       <div class="info-item">
                           <div class="option">Покриття:</div>
                           <div class="info">
                               {{ orderDraft.dgo.compensation }} грн
                           </div>
                       </div>
                       <div class="info-item">
                           <div class="option">Вартість:</div>
                           <div class="info">
                               {{ orderDraft.dgo.price }} грн
                           </div>
                       </div>
                   </div>
                   <div v-if="orderDraft.autolawyer.id">
                       <hr>
                       <div class="info-title">
                           Автоюрист
                       </div>
                       <div class="info-item">
                           <div class="option">Програма:</div>
                           <div class="info">
                               {{ getAutolawyerName(orderDraft.autolawyer) }}
                           </div>
                       </div>
                       <div class="info-item">
                           <div class="option">Вартість:</div>
                           <div class="info">
                               {{ orderDraft.autolawyer.price }} грн
                           </div>
                       </div>
                   </div>
               </div>
<!--                <hr>-->
                <div class="price-earn-info">

                    <div class="info-item">
                        <div class="option">Загальна вартість:</div>
                        <div class="info">
                            <div class="price-lg">{{ getAllPrice(offerData) }} грн</div>
                        </div>
                    </div>
                    <div class="info-item">
                        <div v-if="isPartner" class="option">Заробіток:</div>
                        <div v-else class="option">Заробіток Патнера:</div>
                        <div class="info">
                            <div class="price-sm">{{ getEarnings(offerData) }} грн</div>
                        </div>
                    </div>
                    <div v-if="!isPartner" class="info-item">
                        <div class="option">Заробіток Ваш:</div>
                        <div class="info">
                            <div class="price-sm">{{ getEarnings(offerData, 1) }} грн</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="icon-block" >
            <a class="choice-link" @click="handle()" href="javascript:void(0);">
                <span style="border-bottom: 1px #009BEB dashed;">Обрані пропозиції: {{ choiceCount }}</span>
            </a>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

    export default {
        name: 'ClientChoiceInfo',
        directives: { ClickOutside },
        props: {
            offerData: {
                type: Object,
                default: () => {
                    return {};
                }
            },
            choiceCount: {
                type: Number,
                default: () => {
                    return 0;
                }
            },
        },
        data: function () {
            return {
                condition: {
                    isShow: false,
                },
            }
        },
        computed: {
            isPartner: function () {
                return this.$store.getters.IS_PARTNER_STATUS;
            },
            orderDraft() {
                return this.offerData.responseData?.orderDraft;
            },
        },
        methods: {
            hide: function () {
                return this.condition.isShow = false;
            },
            handle: function () {
                return this.condition.isShow = ! this.condition.isShow
            },
            getAllPrice(offerData) {
                //console.log('isPartner', this.isPartner)
                return (parseFloat(offerData.offerInfo?.price) + (parseFloat((this.orderDraft?.dgo?.price || 0)) + parseFloat((this.orderDraft?.autolawyer?.price || 0)))).toFixed(2);
            },
            getEarnings(offerData, parent = false) {
                if (parent) {
                    return (this.orderDraft?.earningsParent ?? 0);
                }
                return (this.orderDraft?.earnings ?? 0);
            },
            getAutolawyerName: function (item) {
                switch (item.program) {
                    case 2:
                        return "Комфорт";
                    case 3:
                        return "Комфорт плюс";
                    default:
                        return "Стандарт";
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .info-block {
        z-index: 2;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        width: 320px;
        position: absolute;
        background: #FFFFFF;
        top: 30px;
        left: -175px;

        @media (max-width: 768px){
            left: 0;
        }

        .products-info {
            padding: 20px 30px;
        }


        .info-title {
            font-size: 15px;
            font-weight: bold;
            color: #24282C;
        }

        .info-item {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            .info {
                text-wrap: initial;
            }
        }

        .option {
            margin-top: 8px;
            font-family: Circe,serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            color: #999DA6;
        }
        .info {
            font-family: Circe, serif;
            text-align: end;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 22px;
            color: #24282C;

            .price-lg{
                color: #FC9B13;
                font-size: 25px;
            }
            .price-sm{
                color: #FC9B13;
                font-size: 20px;
            }
        }
        svg:hover {
            fill: #FA9B13;
        }
        .price-earn-info {
            border-top: solid 1px #DDE2E5;
            padding: 16px 30px;
            background-color: #F6F7FB;
            .option{
                margin-top: 8px;
                margin-bottom: 8px;
            }
        }
    }

</style>
